<style scoped>
    .layout{
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .layout-logo{
        width: 100px;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        float: left;
        position: relative;
        top: 15px;
        left: 20px;
    }
    .layout-nav{
        width: 420px;
        margin: 0 auto;
        margin-right: 20px;
    }
    .layout-footer-center{
        text-align: center;
    }
    </style>
    <template>
        <div class="layout">
            <Layout>
                    <div mode="horizontal" theme="dark" active-name="1" style="padding:0 50px">
                        <loginfobar />
                    </div>
                <Layout :style="{padding: '0 50px'}">
                    <Breadcrumb :style="{margin: '16px 0'}">
                        <BreadcrumbItem >首页</BreadcrumbItem>

                        <BreadcrumbItem  v-for="(v) in menupathcompute" v-bind:key="v.name">{{v.menutext}}</BreadcrumbItem>
                    </Breadcrumb>
                    <Content :style="{padding: '0px 0', minHeight: '280px', background: '#fff'}">
                        <Layout>
                            <Sider hide-trigger :style="{background: '#fff'}">
                                <Menu  theme="light" ref="controlMenu" width="auto" :active-name="currentMenuName" :open-names="['ziliaoshenbao']"  @on-select="handleSelect">
                                    <MenuItem name="home">
                                        <Icon type="ios-home"></Icon>
                                        <span>{{getMenutext('home')}}</span>
                                    </MenuItem>                              
                                    <MenuGroup title="基础数据配置">
                                        <MenuItem name="baseconfig" v-if="usercategory=='slave'">
                                            <Icon type="ios-list"></Icon>
                                            <span>{{getMenutext('baseconfig')}}</span>
                                        </MenuItem>       
                                        <MenuItem name="template" v-if="usercategory=='slave'">
                                            <Icon type="ios-list"></Icon>
                                            <span>{{getMenutext('template')}}</span>
                                        </MenuItem>                  
                                        <MenuItem name="standard">
                                            <Icon type="md-rose" />
                                            <span>{{getMenutext('standard')}}</span>
                                        </MenuItem>
                                        <Submenu name="ziliaoshenbao">
                                            <template slot="title">
                                                <Icon type="ios-basket" />
                                                {{getMenutext('ziliaoshenbao')}}
                                            </template> 
                                            <MenuItem name="ziliao">
                                                <Icon type="ios-archive-outline" />
                                                <span>{{getMenutext('ziliao')}}</span>
                                            </MenuItem>
                                            <MenuItem name="ziliaoFiles">
                                                <Icon type="ios-document-outline" />
                                                <span>{{getMenutext('ziliaoFiles')}}</span>
                                            </MenuItem>
                                        </Submenu>
                                    </MenuGroup>        
                                    <MenuGroup title="文件信息查询">
                                        <MenuItem name="runfile">
                                            <Icon type="ios-book" />
                                            <span>{{getMenutext('runfile')}}</span>
                                        </MenuItem>
                                        <Submenu name="recorddir">
                                            <template slot="title">
                                                <Icon type="ios-basket" />
                                                {{getMenutext('recorddir')}}
                                            </template> 
                                            <MenuItem name="cerrecord">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('cerrecord')}}</span>
                                            </MenuItem>
                                            <MenuItem name="partcerinfo">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('partcerinfo')}}</span>
                                            </MenuItem>
                                            <MenuItem name="rzbzrecord">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('rzbzrecord')}}</span>
                                            </MenuItem>
                                            <MenuItem name="recordfile">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('recordfile')}}</span>
                                            </MenuItem>
                                        </Submenu>
                                        <Submenu name="qcdir">
                                            <template slot="title">
                                                <Icon type="ios-basket" />
                                                {{getMenutext('qcdir')}}
                                            </template> 
                                            <MenuItem name="cccheckrecord">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('cccheckrecord')}}</span>
                                            </MenuItem>
                                            <MenuItem name="qc">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('qc')}}</span>
                                            </MenuItem>
                                        </Submenu>
                                        <MenuItem name="advise">
                                            <Icon type="ios-book" />
                                            <span>{{getMenutext('advise')}}</span>
                                        </MenuItem>
                                        <Submenu name="purchasedir">
                                            <template slot="title">
                                                <Icon type="ios-basket" />
                                                {{getMenutext('purchasedir')}}
                                            </template> 
                                            <MenuItem name="supplier">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('supplier')}}</span>
                                            </MenuItem>       
                                            <MenuItem name="cgjh">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('cgjh')}}</span>
                                            </MenuItem>  
                                            <MenuItem name="shd">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('shd')}}</span>
                                            </MenuItem>      
                                            <MenuItem name="rkd">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('rkd')}}</span>
                                            </MenuItem>  
                                            <MenuItem name="ckd">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('ckd')}}</span>
                                            </MenuItem>     
                                            <MenuItem name="scjh">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('scjh')}}</span>
                                            </MenuItem>    
                                            <!--
                                            <MenuItem name="cght">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('cght')}}</span>
                                            </MenuItem>   
                                            -->
                                                              
                                            <MenuItem name="purchase">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('purchase')}}</span>
                                            </MenuItem>
                                        </Submenu>
                                        <Submenu name="scequipment">
                                            <template slot="title">
                                                <Icon type="ios-basket" />
                                                {{getMenutext('scequipment')}}
                                            </template> 
                                            <MenuItem name="scsbdj">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('scsbdj')}}</span>
                                            </MenuItem>
                                            <MenuItem name="process">
                                                <Icon type="ios-book" />
                                                <span>{{getMenutext('process')}}</span>
                                            </MenuItem>
                                        </Submenu>
    
                                        <MenuItem name="equipment">
                                            <Icon type="md-cog" />
                                            <span>{{getMenutext('equipment')}}</span>
                                        </MenuItem>
                                    </MenuGroup>
                                    
                                </Menu>
                            </Sider>
                            <Content :style="{padding: '10px 6px', minHeight: '280px', background: '#fff'}">
                                <router-view></router-view>
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
                <!--
                <Footer class="layout-footer-center">2020-2029 &copy; 天津智算科技有限责任公司</Footer>
                  -->
                  <Footer class="layout-footer-center"></Footer>
            </Layout>
        </div>
    </template>
    
    <script>
        String.prototype.endWith=function(endStr){
            var d=this.length-endStr.length;
            return (d>=0&&this.lastIndexOf(endStr)==d);
        }

        import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
        export default {
            name:'CheckDeptDesk',
            data () {
              return {
                currentMenuName:'0',
                userlevel:this._self.$root.$store.state.LogonInfo.level,
                usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
                companycode:this.$route.params.companyCode,
                templatecount:0,
                modelcfg:this._self.$root.$store.state.ModelCfg,
                menupathJSON:[
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/CheckHome',classid:null,name:'home',menutext:'工作台首页'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/baseconfig',classid:null,name:'baseconfig',menutext:'基础信息配置'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/template',classid:null,name:'template',menutext:'文件模板维护'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/standard',classid:null,name:'standard',menutext:'相关标准'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ziliaoshenbao',classid:null,name:'ziliaoshenbao',menutext:'认证申报资料'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ziliao',classid:null,name:'ziliao',menutext:'车型参数导入'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ziliaoAdd',classid:null,name:'ziliaoadd',menutext:'车型新增'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ziliaoFiles',classid:null,name:'ziliaoFiles',menutext:'资料清单'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/qcdir',classid:null,name:'qcdir',menutext:'检验类目录'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/cccheckrecord',classid:null,name:'cccheckrecord',menutext:'检验记录'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/purchasedir',classid:null,name:'purchasedir',menutext:'采购及仓储目录'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/supplier',classid:null,name:'supplier',menutext:'供方目录'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/cgjh',classid:null,name:'cgjh',menutext:'采购计划'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/shd',classid:null,name:'shd',menutext:'送货单/合同/进厂检验'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/rkd',classid:null,name:'rkd',menutext:'入库单'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ckd',classid:null,name:'ckd',menutext:'出库单'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/scjh',classid:null,name:'scjh',menutext:'生产计划'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/cght',classid:null,name:'cght',menutext:'采购合同'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/scequipment',classid:null,name:'scequipment',menutext:'生产设备目录'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/scsbdj',classid:null,name:'scsbdj',menutext:'生产设备点检'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ycwj/1',classid:1,name:'runfile',menutext:'认证运行文件(大夹子)'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ycwj/2',classid:2,name:'purchase',menutext:'其它采购类文件'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ycwj/3',classid:3,name:'qc',menutext:'其它检验类文件'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/recorddir',classid:null,name:'recorddir',menutext:'记录类文件目录'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/cerrecord',classid:null,name:'cerrecord',menutext:'合格证台账'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/partcerinfo',classid:null,name:'partcerinfo',menutext:'证书有效性查询'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/rzbzrecord',classid:null,name:'rzbzrecord',menutext:'认证标识台账'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ycwj/4',classid:4,name:'recordfile',menutext:'记录类文件'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ycwj/5',classid:5,name:'advise',menutext:'售后类文件'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ycwj/6',classid:6,name:'process',menutext:'生产设备类文件'},
                    {path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/ycwj/7',classid:7,name:'equipment',menutext:'检验设备类文件'},         
                ]
              }
            },
            components:{
                loginfobar:UserInfoTitleBar,
            },
            mounted(){
                console.log(this.$route)
                this.$nextTick(()=>{
                    this.currentMenuName = this.getMenuName(this.$route.path)
                    this.$refs.controlMenu.updateActiveName()
                })
                this.readModels()
                this.readTemplateStatus()
                eventBus.$on('templateupdate',(data) => {
                        console.log(data)
                        this.templatecount = data.totalnumber
                })
                eventBus.$on('modelcfgupdate',(data) => {
                        console.log(data)
                        this.modelcfg = data
                })
            },
            beforeDestroy(){
                eventBus.$off("templateupdate");
                eventBus.$off("modelcfgupdate");
            },
            computed:{
                menupathcompute() {
                    return this.menupathJSON.filter(v=>{
                            if(v.path == this.$route.path){
                                return true;
                            }
                        });
                }
            },
            watch: {
                $route: {
                    handler: function(val, oldVal){
                        console.log(val)
                        console.log(oldVal)
                        this.currentMenuName = this.getMenuName(val.path)
                        if(val.path.endWith('baseconfig') || val.path.endWith('template') || val.path.endWith('ziliao')){

                        }
                        else{
                            if(this.templatecount <=0){
                                this.$Modal.error({
                                    title:'错误提示',
                                    content:'模板还未初始化！！',
                                    okText:'确认',
                                    onOk:()=>{
                                        this.$router.push({path: oldVal.path, query: oldVal.query})
                                    },
                                    onCancel:()=>{
                                        this.$router.push({path: oldVal.path, query: oldVal.query})
                                    }
                                })
                                //this.$Message.error({content:'模板还未初始化！！',duration:10})
                                //this.$router.push({path: oldVal.path, query: oldVal.query})
                            }
                            else if(this.modelcfg ==null){
                                this.$Modal.error({
                                    title:'错误提示',
                                    content:'基础配置信息尚未配置！！',
                                    okText:'确认',
                                    onOk:()=>{
                                        this.$router.push({path: oldVal.path, query: oldVal.query})
                                    },
                                    onCancel:()=>{
                                        this.$router.push({path: oldVal.path, query: oldVal.query})
                                    }
                                })
                            }
                        }
                    },
                    // 深度观察监听
                    deep: true
                }
            },
            methods:{
              getMenutext:function(name){
                  for(var v in this.menupathJSON){
                      if(this.menupathJSON[v].name == name){
                          return this.menupathJSON[v].menutext
                      }
                  }
              },  
              getMenuName:function(path,classid){
                  var result=null;
                  for(var v in this.menupathJSON){
                      if(this.menupathJSON[v].path == path){
                            if(this.menupathJSON[v].belongMenu !=null){
                                result = this.menupathJSON[v].belongMenu
                            }
                            else{
                                result = this.menupathJSON[v].name
                            }
                            return result;
                      }
                  }
              },  
              handleSelect:function(name){
                for(var v in this.menupathJSON){
                        if(this.menupathJSON[v].name == name){
                            let topath = this.menupathJSON[v].path
                            let toname = this.menupathJSON[v].name
                            let classid= this.menupathJSON[v].classid
                        
                            console.log(this.$route.path)
                            console.log('enter into '+topath)
                            
                            this.$router.push({path:topath})
                            break;
                        }
                    }
              },
              readModels(){
                this.$axios({
                    method:'post',
                    url:"/api/params/model/list",
                    data:{companyid:this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data)
                        this.$store.commit('changeModelListInfo',res.data.data)
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                    this.readcfg()
                }.bind(this));   
              },
              readcfg(){
                this.$axios({
                    method:'post',
                    url:"/api/checkdept/cfg/get",
                    data: {
                        companyid:this._self.$root.$store.state.LogonInfo.companyid
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    console.log(res)
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.modelcfg = res.data.data
                        this.$store.commit('changeModelCfg',res.data.data)
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        //this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));             
            },
            readTemplateStatus(){
                let posturl ='/api/checkdept/template/private/querycount' 
                this.$axios({
                    method:'post',
                    url:posturl,
                    data: {companyid: this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.templatecount = res.data.data
                        this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            }
            
        }
    }
    </script>    
    <style scoped>
    .ivu-menu-item {
        margin-left: 0px;
        text-align: left;
    }

    .ivu-menu-submenu{
        margin-left: 0px;
        text-align: left;
    }

    .ivu-menu-item-group{
        margin-left: 0px;
        text-align: left;
    }
    .ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title {
        margin-left: 0px;
        text-align: left;
    }


    </style>